.navbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
  li {
    padding: 0 10px;
    text-transform: uppercase;
    &:first-child {
      @media (min-width: @screen-md-min) {
        padding-left: 0;
      }
    }
    a {
      padding-left: 0;
      padding-right: 0;
    }
    &.active {
      a {
        border-bottom: 5px solid @qualinsur-green;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .navbar-collapse {
    padding-left: 10px;
  }
}

@header-logo-height: 54px;
.header {
  background: @qualinsur-white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.header-top {
  .container();
}

.header-top {
  overflow: hidden;
  position: relative;
}

.header-logo {
  float: left;
  margin-right: 30px;
  height: @header-logo-height;
}

.header-motto {
  color: @qualinsur-green;
  font-weight: 300;
  line-height: @header-logo-height;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (max-width: @screen-sm-max) {
    display: none;
  }
}

.header-jobs {
  color: @qualinsur-green;
  font-weight: 600;
  line-height: @header-logo-height;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  right:10px;
  top:0px;
  @media (max-width: @screen-sm-max) {
    display: none;
  }
}