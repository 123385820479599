.advantages {
  .container-flex();
  position: relative;;
  top: -30px;
}

.advantage {
  background: @qualinsur-green;
  color: @qualinsur-white;
  flex-basis: 100%;
  margin-bottom: 30px;
  padding: 6px 10px;
  .title {
    .font-size-bigger();
  }
  @media (min-width: @screen-md-min) {
    flex-basis: 30%;
  }
}

.services {
  .container-flex();
}

.service {
  @service-padding: 20px;
  @service-side-padding: 40px;

  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.14);
  font-weight: 300;
  flex-basis: 100%;
  padding: @service-padding @service-side-padding @service-padding*4;
  margin-bottom: 40px;
  position: relative;
  .font-size-bigger();
  @media (min-width: @screen-md-min) {
    flex-basis: 48%;
  }
  &__title {
    color: @qualinsur-green;
    text-align: center;
  }
  &__subtitle {
    color: @qualinsur-green;
    text-align: center;
    text-transform: uppercase;
  }
  &__image {
    display: block;
    margin: 0 auto;
  }
  &__list {
    margin-top: 40px;
    li {
      margin-top: 12px;
    }
  }

  &--inversed {
    background: @qualinsur-green;
    color: @qualinsur-white;
    .service__title {
      color: @qualinsur-white;
    }
    .service__subtitle {
      color: @qualinsur-white;
    }
  }

  &__case-study-label {
    font-weight: 300;
    margin: @service-padding*2 0 @service-padding;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    &::before {
      border-top: 1px solid @qualinsur-light-grey;
      content: '';
      position: absolute;
      top: -@service-padding;
      left: 50%;
      width: 200px;
      transform: translateX(-50%);
    }
  }

  &__case-study-description {
    .h3();
    font-weight: 300;
  }

  .btn {
    position: absolute;
    bottom: @service-side-padding;
    right: @service-side-padding;
  }
}