// Core variables and mixins
@import "../../node_modules/bootstrap/less/variables.less";
@import "./variables.less";
@import "../../node_modules/bootstrap/less/mixins.less";
// Reset and dependencies
@import "../../node_modules/bootstrap/less/normalize.less";
@import "../../node_modules/bootstrap/less/print.less";
@import "../../node_modules/bootstrap/less/glyphicons.less";
// Core CSS
@import "../../node_modules/bootstrap/less/scaffolding.less";
@import "../../node_modules/bootstrap/less/type.less";
@import "../../node_modules/bootstrap/less/code.less";
@import "../../node_modules/bootstrap/less/grid.less";
@import "../../node_modules/bootstrap/less/tables.less";
@import "../../node_modules/bootstrap/less/forms.less";
@import "../../node_modules/bootstrap/less/buttons.less";
// Components
@import "../../node_modules/bootstrap/less/component-animations.less";
@import "../../node_modules/bootstrap/less/dropdowns.less";
@import "../../node_modules/bootstrap/less/button-groups.less";
@import "../../node_modules/bootstrap/less/input-groups.less";
@import "../../node_modules/bootstrap/less/navs.less";
@import "../../node_modules/bootstrap/less/navbar.less";
//@import "../../node_modules/bootstrap/less/breadcrumbs.less";
//@import "../../node_modules/bootstrap/less/pagination.less";
//@import "../../node_modules/bootstrap/less/pager.less";
//@import "../../node_modules/bootstrap/less/labels.less";
//@import "../../node_modules/bootstrap/less/badges.less";
//@import "../../node_modules/bootstrap/less/jumbotron.less";
//@import "../../node_modules/bootstrap/less/thumbnails.less";
//@import "../../node_modules/bootstrap/less/alerts.less";
//@import "../../node_modules/bootstrap/less/progress-bars.less";
//@import "../../node_modules/bootstrap/less/media.less";
//@import "../../node_modules/bootstrap/less/list-group.less";
//@import "../../node_modules/bootstrap/less/panels.less";
//@import "../../node_modules/bootstrap/less/responsive-embed.less";
//@import "../../node_modules/bootstrap/less/wells.less";
//@import "../../node_modules/bootstrap/less/close.less";
// Components w/ JavaScript
//@import "../../node_modules/bootstrap/less/modals.less";
//@import "../../node_modules/bootstrap/less/tooltip.less";
//@import "../../node_modules/bootstrap/less/popovers.less";
//@import "../../node_modules/bootstrap/less/carousel.less";
// Utility classes
@import "../../node_modules/bootstrap/less/utilities.less";
@import "../../node_modules/bootstrap/less/responsive-utilities.less";

body {
  padding-top: 100px;
  @media (min-width: @screen-md-min) {
    padding-top: 110px;
  }
}

h2, .h2 {
  font-weight: 300;
  font-size: 36px;
  line-height: 53px;
}

h3, .h3 {
  font-size: 22px;
  line-height: 32px;
}

ul {
  list-style: none;
  padding: 0;
}

.container-flex {
  .container();
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  &::before, &::after {
    display: none;
  }
}

.font-size-bigger() {
  font-size: 18px;
  line-height: 26px;
}

.title--inversed {
  color: @qualinsur-white;
  background: @qualinsur-green;
}
.title--list-header {
  color: @qualinsur-green;
  font-weight: normal;
}

.title--block {
  display: block;
}

.coloured-square(@bgcolor: @qualinsur-green, @brcolor: @qualinsur-green, @color: @qualinsur-white) {
  background: @bgcolor;
  border:2px solid @brcolor;
  color: @color;
}

input, select, textarea {
  border-radius: 0;
  display: block;
  margin: 0 0 13px;
  padding: 0 10px;
  line-height: 44px;
  text-transform: uppercase;
  width: 100%;
  .coloured-square(transparent, @qualinsur-black, @qualinsur-black);
}

input, select {
  height: 50px;
}

input[type=submit] {
  line-height: 44px;
  height: 50px;
  padding: 0 48px;
  width: initial;
}

label {
  font-weight: 300;
  text-transform: uppercase;
}

.btn {
  line-height: 44px;
  height: 50px;
  padding: 0 48px;
  text-transform: uppercase;
  transition: background 0.5s ease, color 0.5s ease;
  .coloured-square(@qualinsur-white, @qualinsur-black, @qualinsur-black);
  &:hover {
    .coloured-square();
  }
}

.btn-inversed {
  &:hover {
    .coloured-square(@qualinsur-green, @qualinsur-white, @qualinsur-white);
  }
}

.list-default {
  list-style: disc;
  padding-left: 25px;
}

@import "./header.less";
@import "./intro.less";
@import "./home.less";
@import "./sidebar.less";
@import "detail.less";
@import "./footer.less";
