@intro-padding: 100px;
.intro {
  background: no-repeat center;
  background-size: cover;
  text-align: center;
  padding: @intro-padding 0;
  position: relative;
  @media (min-width: @screen-md-min) {
    padding: @intro-padding*2 0;
  }
  .title {
    color: @qualinsur-white;
    display: block;
    padding: 20px;
    &--inversed {
      display: inline-block;
      margin-left: 15%;
      padding: 20px;
    }
  }

  &-news {
    background-image: url(../img/intro-news.jpg);
    padding: @intro-padding*0.75 0;
    @media (min-width: @screen-md-min) {
      padding: @intro-padding*1.5 0;
    }
    .title {
      position: absolute;
      bottom: 0;
      left: 10%;
    }
  }
}