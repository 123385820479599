.sidebar__block {
  background: @qualinsur-light-grey;
  color: @qualinsur-black;
  padding: 20px;
  margin: 20px 0;
  min-height: 250px;
  .title {
    text-transform: uppercase;
  }
}

.case-study-list {
  & li {
    font-size: 24px;
    line-height: 48px;
    a {
      color: @qualinsur-grey;
      .h3();
      text-transform: uppercase;
    }
  }
}

.sidebar__block--formular {
  background: @qualinsur-green;
  color: @qualinsur-white;
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 70px;
  position: relative;
  .btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}