.footer {
  background-color: @qualinsur-footer-grey;
  color: @qualinsur-green;
  overflow: hidden;
  padding: 40px 0 35px;

  .container-fixed();

  &__column {
    font-weight: 300;
    .make-sm-column(3);
    .make-md-column(2);
    &--with-logo {
      text-align: center;
      @media (min-width: @screen-md-min) {
        padding-top: 25px;
      }
    }
  }

  .title {
    font-weight: normal;
    text-transform: uppercase;
  }

  address {
    font-weight: 300;
    margin: 0;
  }
}