.wrapper {
  .container();
  margin-top: 50px;
  margin-bottom: 100px;
}

.content {
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  overflow: hidden;
  .make-md-column(7);
  h2.title {
    font-size: 36px;
  }
}

.sidebar {
  .make-md-column(5);
}

.contact-list {
  color: @qualinsur-grey;
  font-size: 22px;
  font-weight: 300;
  overflow: hidden;

  &__contact-name {
    .make-xs-column(4);
    padding-left: 0;
  }
  &__contact-phone {
    .make-xs-column(8);
  }
}

.management-person {
  .make-xs-column(12);
  .make-md-column(6);
  @media (min-width: @screen-md-min) {
    padding-left: 0;
  }

  &__preview {
    .make-xs-column(12);
    .make-md-column(5);
  }

  &__description {
    .make-xs-column(12);
    .make-md-column(7);
  }
}

.contact-form {
  .make-xs-column(12);
  .make-md-column(5);
}
.contact-text {
  .make-xs-column(12);
  .make-md-column(7);
}

.contact-person {
  .make-xs-column(12);
  .make-md-column(6);
  .make-lg-column(4);
  img {
    max-width: 100%;
  }
}

.partner-logos {
  .make-xs-column(12);
  &__item {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(3);
    .make-lg-column(2);
    text-align: center;
  }
  img {
    max-width: 100%;
  }
}

.quali-at-work-aktivitaeten-block {
  margin: 40px 0 0;
}

.quali-at-work-mitglieder-logo {
  .make-xs-column(12);
}

.quali-at-work-mitglieder-description {
  .make-xs-column(12);
  margin-bottom: 40px;
  strong {
    display: block;
  }
}